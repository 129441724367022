module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-do_4957f17b5e88015ac4d3b92be1fb9695/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"display":"minimal-ui","icon":"./src/images/romeroIcon.png","name":"Romero Institute","short_name":"Romero Institute","start_url":"/","background_color":"#0050BF","theme_color":"#0050BF","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e26b12dd33ab328f1967a8cd5b0fa209"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3.1_react@18.3.1__react@1_0e4b30417039d234ca02d89419987e56/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
